import PageHeader from '@features/campaigns/details/components/PageHeader/PageHeader';
import CampaignDetailsPage from '@features/campaigns/details/containers/CampaignDetailsPage/CampaignDetailsPage';

function CampaignDetailsPageLayout() {
  return (
    <>
      <PageHeader />
      <section className='content-wrapper'>
        <CampaignDetailsPage />
      </section>
    </>
  );
}

export default CampaignDetailsPageLayout;

import { AvailableAdProducts, CampaignDetails, CampaignFormats } from '@features/campaigns/shared/types/campaign.ts';

export function getAvailableAdProducts(data: CampaignDetails) {
  return Object.keys(CampaignFormats).reduce(
    (acc, key): AvailableAdProducts => {
      let isAdProductAvailable = false;

      if (key === CampaignFormats.DISPLAY) {
        isAdProductAvailable = !!data.adProductDisplay;
      }

      if (key === CampaignFormats.VIDEO) {
        isAdProductAvailable = !!data.adProductVideo;
      }

      return { ...acc, [key]: isAdProductAvailable };
    },
    {
      [CampaignFormats.DISPLAY]: false,
      [CampaignFormats.VIDEO]: false,
    },
  );
}

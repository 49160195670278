import cn from 'classnames';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Dispatch, SetStateAction, useEffect } from 'react';

import {
  PerformanceChartsValue,
  PerformanceChartsValueType,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/utils/generateChartData.ts';
import { optionsBasedOnSelectedFormat } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/ChartTypeButtons/utils/optionsToSelect.ts';
import { CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';

import styles from './ChartTypeButtons.module.scss';

interface ChartTypeButtonsProps {
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<PerformanceChartsValueType>>;
  selectedFormat?: CampaignFormat[];
}

function ChartTypeButtons({ selectedValue, setSelectedValue, selectedFormat }: ChartTypeButtonsProps) {
  const onChangeHandler = (e: SelectButtonChangeEvent) => {
    setSelectedValue(e.value);
  };

  const itemTemplate = (option: { value: string; label: string }) => {
    return (
      <div className={styles.itemWrapper} data-testid={`${option.label}-chart-type-button`}>
        <span>{option.label}</span>
      </div>
    );
  };

  useEffect(() => {
    setSelectedValue(PerformanceChartsValue.IMPRESSIONS);
  }, [selectedFormat]);

  const options = optionsBasedOnSelectedFormat(selectedFormat);

  return (
    <SelectButton
      options={options}
      unselectable={false}
      value={selectedValue}
      onChange={(e) => onChangeHandler(e)}
      optionLabel='label'
      itemTemplate={itemTemplate}
      className={cn(styles.selectButtonWrapper, options.length === 1 && styles.singleSelectButtonWrapper)}
      data-testid={'chart-type-select-buttons'}
    />
  );
}

export default ChartTypeButtons;

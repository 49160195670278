import {
  Actions,
  CampaignDetailsReducerAction,
  CampaignDetailsReducerState,
} from '@features/campaigns/list/types/campaignDetailsReducer';

export const initialState: CampaignDetailsReducerState = {
  filters: {
    format: undefined,
    startDate: undefined,
    endDate: undefined,
  },
};

export const reducer = (
  state: CampaignDetailsReducerState,
  { payload, type }: CampaignDetailsReducerAction,
): CampaignDetailsReducerState => {
  switch (type) {
    case Actions.CHANGE_FORMAT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          format: payload?.filters?.format,
        },
      };

    case Actions.CHANGE_START_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: payload?.filters?.startDate,
        },
      };

    case Actions.CHANGE_END_DATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          endDate: payload?.filters?.endDate,
        },
      };

    case Actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };

    default:
      return state;
  }
};

import {
  PerformanceChartsValue,
  PerformanceChartsValueType,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/utils/generateChartData.ts';
import {
  isSelectedDisplayFormat,
  isSelectedVideoFormat,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/utils/selectedFormat.ts';
import {
  CampaignPerformanceAllType,
  CampaignPerformanceDisplayType,
  CampaignPerformanceVideoType,
} from '@features/campaigns/performance/types/performance.ts';
import { CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';
import { ICON_VARIANTS, IconVariants } from '@shared/components/Icon/IconMapper.ts';

interface HighlightDataPerformance {
  label: string;
  value?: number;
  isMoneyValue: boolean;
  iconVariant: IconVariants;
  isFrequencyValue?: boolean;
  isEngagementValue?: boolean;
  isActivationValue?: boolean;
  isTimeSpent?: boolean;
  subtitleLabel?: string;
  subtitleValue?: number;
}

type ReportingDataType =
  | CampaignPerformanceDisplayType
  | CampaignPerformanceVideoType
  | CampaignPerformanceAllType
  | null
  | undefined;

const generateKeyMetricsForDisplayFormat = (
  reportingData: ReportingDataType,
  chartDataType: PerformanceChartsValueType,
) => {
  const parsedReportingData = reportingData as CampaignPerformanceDisplayType | null | undefined;
  if (chartDataType === PerformanceChartsValue.IMPRESSIONS) {
    const keyMetrics = [
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
        value: parsedReportingData?.summary.impressions,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.eye,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.visits',
        value: parsedReportingData?.summary.visits,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.user,
        subtitleLabel: 'page.campaignDetails.performanceTab.keyMetrics.activation',
        subtitleValue: parsedReportingData?.summary.activationPercentage,
        isActivationValue: true,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.actions',
        value: parsedReportingData?.summary.actions,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.barChart,
        subtitleLabel: 'page.campaignDetails.performanceTab.keyMetrics.engagement',
        subtitleValue: parsedReportingData?.summary.engagement,
        isEngagementValue: true,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.timeSpent',
        value: parsedReportingData?.summary.timeSpentMinutes,
        isMoneyValue: false,
        isTimeSpent: true,
        iconVariant: ICON_VARIANTS.clock,
      },
    ];
    return keyMetrics;
  }

  if (chartDataType === PerformanceChartsValue.VISITS || chartDataType === PerformanceChartsValue.ACTIONS) {
    const keyMetrics = [
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
        value: parsedReportingData?.summary.impressions,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.eye,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.visits',
        value: parsedReportingData?.summary.visits,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.user,
        subtitleLabel: 'page.campaignDetails.performanceTab.keyMetrics.activation',
        subtitleValue: parsedReportingData?.summary.activationPercentage,
        isActivationValue: true,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.actions',
        value: parsedReportingData?.summary.actions,
        isMoneyValue: false,
        iconVariant: ICON_VARIANTS.barChart,
        subtitleLabel: 'page.campaignDetails.performanceTab.keyMetrics.engagement',
        subtitleValue: parsedReportingData?.summary.engagement,
        isEngagementValue: true,
      },
      {
        label: 'page.campaignDetails.performanceTab.keyMetrics.timeSpent',
        value: parsedReportingData?.summary.timeSpentMinutes,
        isMoneyValue: false,
        isTimeSpent: true,
        iconVariant: ICON_VARIANTS.clock,
      },
    ];
    return keyMetrics;
  }
};

const generateKeyMetricsForVideoFormat = (reportingData: ReportingDataType) => {
  const parsedReportingData = reportingData as CampaignPerformanceVideoType | null | undefined;
  const keyMetrics = [
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
      value: parsedReportingData?.summary.impressions,
      isMoneyValue: false,
      iconVariant: ICON_VARIANTS.eye,
    },
  ];

  return keyMetrics;
};

const generateKeyMetricsForAllFormat = (reportingData: ReportingDataType) => {
  const parsedReportingData = reportingData as CampaignPerformanceAllType | null | undefined;

  const keyMetrics = [
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.impressions',
      value: parsedReportingData?.summary.impressions,
      isMoneyValue: false,
      iconVariant: ICON_VARIANTS.eye,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.uniqueDevices',
      value: parsedReportingData?.summary.uniqueDevices,
      isMoneyValue: false,
      iconVariant: ICON_VARIANTS.phone,
    },
    {
      label: 'page.campaignDetails.performanceTab.keyMetrics.frequency',
      value: parsedReportingData?.summary.frequency,
      isMoneyValue: false,
      isFrequencyValue: true,
      iconVariant: ICON_VARIANTS.clock,
    },
  ];

  return keyMetrics;
};

export const getKeyMetrics = (
  chartDataType: PerformanceChartsValueType,
  reportingData?: ReportingDataType,
  format?: CampaignFormat[],
): HighlightDataPerformance[] => {
  if (format?.length && isSelectedDisplayFormat(format)) {
    return generateKeyMetricsForDisplayFormat(reportingData, chartDataType) || [];
  }

  if (format?.length && isSelectedVideoFormat(format)) {
    return generateKeyMetricsForVideoFormat(reportingData) || [];
  }

  return generateKeyMetricsForAllFormat(reportingData) || [];
};

import { format } from 'date-fns';

import { PptxType, PptxTypes } from '@features/campaigns/shared/types/pptxType.ts';

// TODO - remove whole file when working on https://clearcodehq.atlassian.net/browse/PBLTL-1184

interface GetPPTXFileNameParams {
  pptxType: PptxType;
  campaignStartDate: string | undefined;
  campaignSequentialId: number | undefined;
  campaignName: string | undefined;
}

export function getPPTXFileName({
  pptxType,
  campaignStartDate = '',
  campaignSequentialId,
  campaignName = '',
}: GetPPTXFileNameParams) {
  const type = pptxType === PptxTypes.REPORT ? ' - report' : '';
  const formattedStartDate = format(new Date(campaignStartDate), 'dd-MM-yyyy');
  const fileName = `${campaignName} - ${campaignSequentialId || ''}${type} - ${formattedStartDate}.pptx`;

  return fileName;
}

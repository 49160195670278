import { useQuery } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess.tsx';
import ExportButton from '@features/campaigns/details/shared/components/ExportButton/ExportButton.tsx';
import { ExportMenu } from '@features/campaigns/details/shared/components/ExportMenu/ExportMenu.tsx';
import { downloadPptxPerformancePresentation } from '@features/campaigns/performance/services/downloadPptxPerformancePresentation.ts';
import commonStyles from '@features/campaigns/shared/components/ExportMenu/ExportMenu.module.scss';
import PptxMenuItem from '@features/campaigns/shared/components/ExportMenu/PptxMenuItem/PptxMenuItem.tsx';
import useCampaignDetails from '@features/campaigns/shared/hooks/useCampaignDetails.ts';
import { PptxTypes } from '@features/campaigns/shared/types/pptxType.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { Permissions } from '@shared/consts/permissions.ts';
import { getPPTXFileName } from '@shared/utils/getPPTXFileName/getPPTXFileName.ts';

export function ExportReport() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const [shouldDownloadPptx, setShouldDownloadPptx] = useState(false);
  const menu = useRef<Menu>(null);
  const { campaignId } = useParams();
  const { isFetching: isFetchingCampaignDetails, data: campaignDetails } = useCampaignDetails({
    campaignId: campaignId!,
  });

  const {
    data: pptxData,
    isFetching: isFetchingPptxData,
    isError,
  } = useQuery({
    queryKey: [shouldDownloadPptx, campaignId],
    queryFn: () => downloadPptxPerformancePresentation(campaignId!),
    enabled: shouldDownloadPptx,
    gcTime: 0,
  });

  const handleOnPptxItemClick = () => {
    setShouldDownloadPptx(true);
  };

  const itemRenderer = (item: MenuItem) => (
    <AllowAccess
      requiredPermissions={[
        Permissions.CAMPAIGN_EXPORT_ALL,
        Permissions.CAMPAIGN_EXPORT_OWNED,
        Permissions.CAMPAIGN_EXPORT_ASSIGNED_ADVERTISERS,
      ]}
    >
      <PptxMenuItem item={item} handleOnClick={handleOnPptxItemClick} menuReference={menu} />
    </AllowAccess>
  );

  const items = [
    {
      items: [
        {
          label: t('page.campaignDetails.campaignDetailsTab.exportOptions.pptx'),
          className: commonStyles.menuItem,
          template: itemRenderer,
        },
      ],
    },
  ];

  useEffect(() => {
    if (pptxData && shouldDownloadPptx) {
      const fileName = getPPTXFileName({
        pptxType: PptxTypes.REPORT,
        campaignStartDate: campaignDetails?.startDate,
        campaignSequentialId: campaignDetails?.sequentialId,
        campaignName: campaignDetails?.name,
      });

      saveAs(pptxData, fileName);
      setShouldDownloadPptx(false);
    }

    if (isError) {
      setShouldDownloadPptx(false);
    }
  }, [pptxData, shouldDownloadPptx, isError]);

  return (
    <>
      <ExportButton
        menuReference={menu}
        isLoading={isFetchingCampaignDetails || isFetchingPptxData}
        label='page.campaignDetails.performanceTab.exportReportButton'
        dataTestId='performance-export-report-button'
      />
      <ExportMenu menuItems={items} menuRef={menu} dataTestId='export-report-menu' />
    </>
  );
}

import type { BubbleDataPoint, Chart as ChartJS, ChartTypeRegistry, LegendItem, Point, TooltipModel } from 'chart.js';
import { format } from 'date-fns';

import { GraphDataType } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/Charts.types.ts';
import { ValueOf } from '@shared/types/common.ts';
import { objectKeys } from '@shared/utils/objectKeys.ts';

interface DataWithDates {
  dates: string[];
}

export const formatDates = (data: GraphDataType) => {
  if (!data) {
    return null;
  }

  objectKeys(data).forEach((key) => {
    if ('dates' in data[key]) {
      const dataWithDates = data[key] as DataWithDates;

      dataWithDates.dates = dataWithDates.dates.map((date) => format(new Date(date), 'dd-MM-yyyy'));
    }
  });

  return data;
};

export const PerformanceChartsValue = {
  IMPRESSIONS: 'Impressions',
  VISITS: 'Visits',
  ACTIONS: 'Actions',
  DEVICES: 'Devices',
  FREQUENCY: 'Frequency',
  ACTIVATION: 'Activation',
  ENGAGEMENT: 'Engagement',
} as const;

export type PerformanceChartsValueType = ValueOf<typeof PerformanceChartsValue>;

const commonGraphStyles = {
  pointRadius: 6,
  pointBorderWidth: 3,
  pointBorderColor: 'rgb(255, 255, 255)',
};

const externalTooltipHandler = ({
  chart,
  tooltip,
}: {
  chart: ChartJS<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>;
  tooltip: TooltipModel<'line'>;
}) => {
  // Tooltip Element
  const tooltipEl = chart.canvas.parentNode!.querySelector('#chartjs-tooltip') as HTMLElement;

  if (tooltipEl) {
    tooltipEl.style.background = '#495057';
    tooltipEl.style.padding = '10px 10px 3px 10px';
    tooltipEl.style.boxShadow = '0px 2px 12px 0px rgba(0, 0, 0, 0.1)';
    tooltipEl.style.borderRadius = '6px';
    tooltipEl.style.color = '#ffffff';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
  }

  const tooltipContentWrapper = document.createElement('div');

  tooltipEl.appendChild(tooltipContentWrapper);
  chart.canvas.parentNode!.appendChild(tooltipEl);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: { lines: string[] }) => b.lines);

    const titleWrapper = document.createElement('div');

    titleLines.forEach((title: string) => {
      const p = document.createElement('p');
      p.style.margin = '0 0 7px 0';
      p.style.fontWeight = '500';

      const text = document.createTextNode(title);

      p.appendChild(text);
      titleWrapper.appendChild(p);
    });

    const contentWrapper = document.createElement('div');
    contentWrapper.style.display = 'flex';
    contentWrapper.style.flexDirection = 'column';

    bodyLines.forEach((body: string[], i: string | number) => {
      const colors = tooltip.labelColors[i as number];

      const elementContainer = document.createElement('div') as HTMLSpanElement;
      elementContainer.style.display = 'flex';
      elementContainer.style.flexDirection = 'row';
      elementContainer.style.alignItems = 'center';
      elementContainer.style.marginBottom = '7px';

      const boxSpan = document.createElement('span') as HTMLSpanElement;
      boxSpan.style.background = `${colors.backgroundColor}`;
      boxSpan.style.borderColor = `${colors.borderColor}`;
      boxSpan.style.marginRight = '7px';
      boxSpan.style.height = '7px';
      boxSpan.style.width = '7px';
      boxSpan.style.display = 'flex';
      boxSpan.style.borderRadius = '50%';

      const textSpan = document.createElement('span');
      textSpan.style.fontWeight = '400';

      const text = document.createTextNode(body[0]);

      if (text.textContent?.includes('Activation')) {
        text.textContent = text.textContent + '%';
      }

      textSpan.appendChild(text);
      elementContainer.appendChild(boxSpan);
      elementContainer.appendChild(textSpan);
      contentWrapper.appendChild(elementContainer);
    });

    const rootElement = tooltipEl.querySelector('div');

    // Remove old children
    while (rootElement!.firstChild) {
      rootElement!.firstChild.remove();
    }

    // Add new children
    rootElement!.appendChild(titleWrapper);
    rootElement!.appendChild(contentWrapper);
  }
  const positionX = chart.canvas.getBoundingClientRect().x;
  const positionY = chart.canvas.getBoundingClientRect().y;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY - 75 + 'px';
};

export const options = (showY1Axis: boolean) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2.75 / 1,
    interaction: {
      mode: 'index' as const,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: { family: 'Inter-Variable', size: 14, weight: 500 },
          color: '#495057',
          padding: 30,
          usePointStyle: true,
        },
        display: false,
      },
      filler: {},
      tooltip: {
        usePointStyle: true,
        backgroundColor: '#495057',
        color: '#ffffff',
        titleFont: { family: 'Inter-Variable', size: 14, weight: 400, lineHeight: 1.7 },
        bodyFont: { family: 'Inter-Variable', size: 14, weight: 300, lineHeight: 1.7 },
        padding: 10.5,
        enabled: false,
        external: externalTooltipHandler,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
      y1: {
        type: 'linear' as const,
        display: showY1Axis,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(
    chart: ChartJS<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>,
  ) {
    if (chart) {
      const items = chart?.options.plugins?.legend?.labels?.generateLabels!(chart);
      const ul = document.createElement('ul');
      ul.style.display = 'flex';
      ul.style.justifyContent = 'center';
      ul.style.flexDirection = 'row';
      ul.style.margin = '20px 0 0 0';
      ul.style.padding = '0';

      items?.forEach((item: LegendItem) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginRight = '40px';

        li.onclick = () => {
          chart.setDatasetVisibility(item.datasetIndex!, !chart.isDatasetVisible(item.datasetIndex!));
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = `${item.fillStyle}`;
        boxSpan.style.borderColor = `${item.strokeStyle}`;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = '0';
        boxSpan.style.height = '12px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '12px';
        boxSpan.style.borderRadius = '50%';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = '#495057';
        textContainer.style.fontWeight = '500';
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);
        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });

      const jsLegend = document.getElementById('label-container');
      // Remove old legend items
      while (jsLegend!.firstChild) {
        jsLegend!.firstChild.remove();
      }
      jsLegend!.appendChild(ul);
    }
  },
};

const impressionsGraphData = (data: GraphDataType) => {
  return {
    labels: data?.impressionsGraph.dates,
    datasets: [
      {
        label: PerformanceChartsValue.IMPRESSIONS,
        data: data?.impressionsGraph.values,
        borderColor: 'rgba(168, 85, 247, 1)',
        backgroundColor: 'rgba(168, 85, 247, 1)',
        tension: 0.5,
        ...commonGraphStyles,
        fill: {
          target: 'origin',
          above: 'rgba(168, 85, 247, 0.1)', // Area above the origin
          below: 'rgba(168, 85, 247, 0.1)', // // Area below the origin
        },
      },
    ],
  };
};

const devicesGraphData = (data: GraphDataType) => {
  if (data && 'uniqueDevicesGraph' in data) {
    return {
      labels: data?.uniqueDevicesGraph.dates,
      datasets: [
        {
          label: PerformanceChartsValue.DEVICES,
          data: data?.uniqueDevicesGraph.values,
          borderColor: 'rgba(59, 130, 246, 1)',
          backgroundColor: 'rgba(59, 130, 246, 1)',
          tension: 0.5,
          ...commonGraphStyles,
          fill: {
            target: 'origin',
            above: 'rgba(59, 130, 246, 0.1)', // Area above the origin
            below: 'rgba(59, 130, 246, 0.1)', // Area below the origin
          },
        },
      ],
    };
  }
};

const frequencyGraphData = (data: GraphDataType) => {
  if (data && 'frequencyGraph' in data) {
    return {
      labels: data?.frequencyGraph.dates,
      datasets: [
        {
          label: PerformanceChartsValue.FREQUENCY,
          data: data?.frequencyGraph.values,
          borderColor: 'rgba(34, 197, 94, 1)',
          backgroundColor: 'rgba(34, 197, 94, 1)',
          tension: 0.5,
          ...commonGraphStyles,
          fill: {
            target: 'origin',
            above: 'rgba(34, 197, 94, 0.1)', // Area above the origin
            below: 'rgba(34, 197, 94, 0.1)', // Area below the origin
          },
        },
      ],
    };
  }
};

const visitsGraphData = (data: GraphDataType) => {
  if (data && 'visitsGraph' in data) {
    return {
      labels: data?.visitsGraph.dates,
      datasets: [
        {
          label: PerformanceChartsValue.ACTIVATION,
          data: data?.visitsGraph.activation,
          borderColor: 'rgba(59, 130, 246, 1)',
          backgroundColor: 'rgba(59, 130, 246, 1)',
          tension: 0,
          ...commonGraphStyles,
          yAxisID: 'y',
        },
        {
          label: PerformanceChartsValue.VISITS,
          data: data?.visitsGraph.visits,
          borderColor: 'rgba(255, 255, 255, 0)',
          backgroundColor: 'rgba(234, 179, 8, 1)',
          tension: 0.4,
          pointRadius: 0,
          pointBorderWidth: 0,
          yAxisID: 'y1',
          fill: {
            target: 'origin',
            above: 'rgba(234, 179, 8, 0.15)', // Area above the origin
            below: 'rgba(234, 179, 8, 0.15)', // Area below the origin
          },
        },
      ],
    };
  }
};

const actionsGraphData = (data: GraphDataType) => {
  if (data && 'actionsGraph' in data) {
    return {
      labels: data?.actionsGraph.dates,
      datasets: [
        {
          label: PerformanceChartsValue.ENGAGEMENT,
          data: data?.actionsGraph.engagement,
          borderColor: 'rgba(34, 197, 94, 1)',
          backgroundColor: 'rgba(34, 197, 94, 1)',
          tension: 0,
          ...commonGraphStyles,
          yAxisID: 'y',
        },
        {
          label: PerformanceChartsValue.ACTIONS,
          data: data?.actionsGraph.actions,
          borderColor: 'rgba(255, 255, 255, 0)',
          backgroundColor: 'rgba(234, 179, 8, 1)',
          tension: 0.4,
          pointRadius: 0,
          pointBorderWidth: 0,
          yAxisID: 'y1',
          fill: {
            target: 'origin',
            above: 'rgba(234, 179, 8, 0.15)', // Area above the origin
            below: 'rgba(234, 179, 8, 0.15)', // Area below the origin
          },
        },
      ],
    };
  }
};

export const generateChartData = (data: GraphDataType, selectedChartType: PerformanceChartsValueType) => {
  if (selectedChartType === PerformanceChartsValue.IMPRESSIONS) {
    return impressionsGraphData(data);
  }

  if (selectedChartType === PerformanceChartsValue.DEVICES) {
    return devicesGraphData(data);
  }

  if (selectedChartType === PerformanceChartsValue.FREQUENCY) {
    return frequencyGraphData(data);
  }

  if (selectedChartType === PerformanceChartsValue.VISITS) {
    return visitsGraphData(data);
  }

  if (selectedChartType === PerformanceChartsValue.ACTIONS) {
    return actionsGraphData(data);
  }

  return undefined;
};

import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import ActionButtons from '@features/campaigns/details/components/ActionButtons/ActionButtons';
import CampaignDetails from '@features/campaigns/details/components/CampaignDetails/CampaignDetails';
import { getAvailableAdProducts } from '@features/campaigns/list/utils/getAvailableAdProducts.ts';
import PerformanceTabPrevVersion from '@features/campaigns/performance/components/PerformanceTabPrevVersion/PerformanceTabPrevVersion';
import Performance from '@features/campaigns/performance/containers/Performance/Performance';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner';
import useCampaignDetails from '@features/campaigns/shared/hooks/useCampaignDetails';
import { CampaignStatus, CampaignStatuses } from '@features/campaigns/shared/types/campaign';
import placeholderCommonStyles from '@shared/components/PlaceholderCard/PlaceholderCard.module.scss';
import { PlaceholderCard } from '@shared/components/PlaceholderCard/PlaceholderCard.tsx';
import { i18nNameSpace } from '@shared/consts/i18n';
import { FeatureFlagPossibleState } from '@shared/providers/FeatureFlagsProvider/FeatureFlagsProvider';
import { useFeatureFlags } from '@shared/providers/FeatureFlagsProvider/useFeatureFlags';
import { routePaths } from '@shared/router/routePaths';

import { campaignDetailsDTO, CampaignDetailsViewData } from './CampaignDetailsPage.dto';
import styles from './CampaignDetailsPage.module.scss';

const compareDateWithToday = (date: string) => {
  const today = new Date();
  const dateToCompare = new Date(date);

  return dateToCompare <= today;
};

const availableStatusesToPerformanceTab: CampaignStatus[] = [
  CampaignStatuses.DELIVERING,
  CampaignStatuses.COMPLETED,
  CampaignStatuses.FAILED,
  CampaignStatuses.PAUSED,
];

function CampaignDetailsPage() {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { pathname } = useLocation();
  const { campaignId } = useParams();
  const isPerformanceView = pathname.includes(routePaths.campaignPerformance.url(campaignId!));
  const activeTabIndex = isPerformanceView ? '1' : '0';
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);
  const { FeatureFlag_Reporting } = useFeatureFlags();
  const isReportingFeatureFlagActive = FeatureFlag_Reporting === FeatureFlagPossibleState.ACTIVE;

  const { isFetching, data } = useCampaignDetails({ campaignId: campaignId! });
  const setActiveTabHandler = (tabIndex: number) => {
    setActiveIndex(`${tabIndex}`);
  };

  if (!data) {
    return (
      <div className={placeholderCommonStyles.placeholderCardWrapper}>
        <PlaceholderCard textKey='placeholderLoading' />
      </div>
    );
  }

  const availableAdProducts = getAvailableAdProducts(data);
  const campaignDetailsData: CampaignDetailsViewData = campaignDetailsDTO(data);
  const invalidSections = data.invalidSections;
  const areAllRequiredFieldFilledIn = invalidSections.length === 0;
  const hasCampaignAlreadyBegun = compareDateWithToday(data.startDate);
  const shouldDisplayPerformanceTab =
    availableStatusesToPerformanceTab.includes(data.status) && hasCampaignAlreadyBegun;
  const id = campaignDetailsData.billingSequentialId
    ? `${campaignDetailsData.sequentialId} - ${campaignDetailsData.billingSequentialId}`
    : campaignDetailsData.sequentialId;

  return (
    <div className={styles.wrapper}>
      {!isPerformanceView && isFetching && <Spinner variant='fullScreenOverlay' />}
      <div className={styles.contentWrapper}>
        <ActionButtons
          shouldSubmitButtonBeDisabled={!areAllRequiredFieldFilledIn}
          campaignName={campaignDetailsData.campaignDetails.campaignName}
          campaignAccessRights={campaignDetailsData.accessRights}
        />
        <div className={styles.campaignDetailsWrapper}>
          <h2 className={styles.campaignName}>{campaignDetailsData.campaignDetails.campaignName}</h2>
          <p className={styles.campaignSubname}>{id}</p>
        </div>
        <TabView activeIndex={Number(activeIndex)} onTabChange={(e) => setActiveTabHandler(e.index)}>
          <TabPanel header={t('page.campaignDetails.viewTab.campaignDetails')}>
            {campaignId && <Navigate to={routePaths.campaignDetails.url(campaignId)} />}
            <CampaignDetails data={campaignDetailsData} invalidSections={invalidSections} />
          </TabPanel>
          {shouldDisplayPerformanceTab && (
            <TabPanel header={t('page.campaignDetails.viewTab.performance')}>
              {campaignId && <Navigate to={routePaths.campaignPerformance.url(campaignId)} />}
              {isReportingFeatureFlagActive ? (
                <Performance
                  campaignEndDate={campaignDetailsData.endDate}
                  campaignStartDate={campaignDetailsData.startDate}
                  campaignFormat={campaignDetailsData.formatType}
                />
              ) : (
                <PerformanceTabPrevVersion availableAdProducts={availableAdProducts} />
              )}
            </TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}

export default CampaignDetailsPage;

import { t } from 'i18next';
import { SelectItem } from 'primereact/selectitem';

import { PerformanceChartsValue } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/utils/generateChartData.ts';
import {
  isSelectedAllFormat,
  isSelectedDisplayFormat,
  isSelectedVideoFormat,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/utils/selectedFormat.ts';
import { CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

export const optionsBasedOnSelectedFormat = (selectedFormat: CampaignFormat[] | undefined) => {
  const options: SelectItem[] = [
    {
      value: PerformanceChartsValue.IMPRESSIONS,
      label: t('page.campaignDetails.performanceTab.impressionsButton', { ns: i18nNameSpace.CAMPAIGNS }),
      icon: 'pi pi-eye',
    },
    {
      value: PerformanceChartsValue.VISITS,
      label: t('page.campaignDetails.performanceTab.visitsButton', { ns: i18nNameSpace.CAMPAIGNS }),
      icon: 'pi pi-users',
    },
    {
      value: PerformanceChartsValue.ACTIONS,
      label: t('page.campaignDetails.performanceTab.actionsButton', { ns: i18nNameSpace.CAMPAIGNS }),
      icon: 'pi pi-chart-line',
    },
    {
      value: PerformanceChartsValue.DEVICES,
      label: t('page.campaignDetails.performanceTab.devicesButton', { ns: i18nNameSpace.CAMPAIGNS }),
      icon: 'pi pi-desktop',
    },
    {
      value: PerformanceChartsValue.FREQUENCY,
      label: t('page.campaignDetails.performanceTab.frequencyButton', { ns: i18nNameSpace.CAMPAIGNS }),
      icon: 'pi pi-clock',
    },
  ];

  let filteredOptions = options;
  const defaultFilteredOptions = filteredOptions.filter(
    (option) => option.value !== PerformanceChartsValue.VISITS && option.value !== PerformanceChartsValue.ACTIONS,
  );

  if (isSelectedDisplayFormat(selectedFormat)) {
    filteredOptions = filteredOptions.filter(
      (option) => option.value !== PerformanceChartsValue.DEVICES && option.value !== PerformanceChartsValue.FREQUENCY,
    );

    return filteredOptions;
  }

  if (isSelectedVideoFormat(selectedFormat)) {
    filteredOptions = filteredOptions.filter((option) => option.value === PerformanceChartsValue.IMPRESSIONS);

    return filteredOptions;
  }

  if (isSelectedAllFormat(selectedFormat)) {
    filteredOptions = filteredOptions.filter(
      (option) => option.value !== PerformanceChartsValue.VISITS && option.value !== PerformanceChartsValue.ACTIONS,
    );

    return filteredOptions;
  }

  return defaultFilteredOptions;
};

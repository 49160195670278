import { Dialog } from 'primereact/dialog';
import { PropsWithChildren } from 'react';

import { DefaultActionsFooter } from '@shared/components/Modal/Footers/DefaultActionsFooter/DefaultActionsFooter';
import { useLeaveGuardModalContext } from '@shared/providers/LeaveGuardModalProvider/useLeaveGuardModalContext';

interface LeaveGuardModalProps {
  cancelCallback: () => void;
  submitCallback: () => void;
  headerText: string;
  cancelLabelText: string;
  submitLabelText: string;
}

export function LeaveGuardModal({
  cancelCallback,
  submitCallback,
  headerText,
  submitLabelText,
  cancelLabelText,
  children,
}: PropsWithChildren<LeaveGuardModalProps>) {
  const { isOpen, toggleModal, isLoading } = useLeaveGuardModalContext();

  return (
    <Dialog
      visible={isOpen}
      onHide={toggleModal}
      draggable={false}
      resizable={false}
      header={headerText}
      footer={
        <DefaultActionsFooter
          submitLabel={submitLabelText}
          cancelLabel={cancelLabelText}
          cancelCallback={cancelCallback}
          submitCallback={submitCallback}
          submitCallbackActionIsLoading={isLoading}
        />
      }
    >
      {children}
    </Dialog>
  );
}

import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';

import { initialState, reducer } from '@features/campaigns/details/reducers/campaignDetailsReducer.ts';
import { Reducer } from '@features/campaigns/list/types/campaignDetailsReducer.ts';
import PerformanceCharts from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/Charts.tsx';
import {
  formatDates,
  PerformanceChartsValue,
  PerformanceChartsValueType,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/utils/generateChartData.ts';
import ChartTypeButtons from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/ChartTypeButtons/ChartTypeButtons.tsx';
import { ExportReport } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/ExportReport/ExportReport.tsx';
import { getKeyMetrics } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/utils/generateKeyMetricsData.ts';
import Filters from '@features/campaigns/shared/components/Filters/Filters.tsx';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys.ts';
import { Highlights } from '@features/campaigns/shared/containers/Review/Highlights/Highlights.tsx';
import {
  CampaignPerformanceQueryParams,
  getCampaignPerformanceAllType,
  getCampaignPerformanceDisplayType,
  getCampaignPerformanceVideoType,
} from '@features/campaigns/shared/services/campaign/performance.ts';
import { AvailableAdProducts } from '@features/campaigns/shared/types/campaign.ts';
import { FILTERS } from '@features/campaigns/shared/types/filters.ts';

import styles from './PerformanceTab.module.scss';
import { isSelectedDisplayFormat, isSelectedVideoFormat } from './utils/selectedFormat.ts';

interface PerformanceTabProps {
  availableAdProducts?: AvailableAdProducts;
}

function PerformanceTabPrevVersion({ availableAdProducts }: PerformanceTabProps) {
  const [selectedChartTypeButton, setSelectedChartTypeButton] = useState<PerformanceChartsValueType>(
    PerformanceChartsValue.IMPRESSIONS,
  );
  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);
  const { campaignId } = useParams();

  const query: CampaignPerformanceQueryParams = {
    startDate: undefined,
    endDate: undefined,
  };

  const fetchFunction = () => {
    if (state.filters.startDate) {
      const startDate = new Date(state.filters.startDate as Date);
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');

      query.startDate = formattedStartDate;
    }

    if (state.filters.endDate) {
      const endDate = new Date(state.filters.endDate as Date);
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      query.endDate = formattedEndDate;
    }

    if (campaignId && state.filters.format?.length && isSelectedDisplayFormat(state.filters.format)) {
      return getCampaignPerformanceDisplayType(campaignId, query);
    }

    if (campaignId && state.filters.format?.length && isSelectedVideoFormat(state.filters.format)) {
      return getCampaignPerformanceVideoType(campaignId, query);
    }

    if (campaignId && !isSelectedDisplayFormat(state.filters.format) && !isSelectedVideoFormat(state.filters.format)) {
      return getCampaignPerformanceAllType(campaignId, query);
    }
  };

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_PERFORMANCE, campaignId, state],
    queryFn: async () => {
      const data = await fetchFunction();
      const dataWithFormattedDates = formatDates(data);

      return dataWithFormattedDates;
    },
    placeholderData: keepPreviousData,
  });

  const keyMetricsData = getKeyMetrics(selectedChartTypeButton, data, state.filters.format);

  const highlightsClassName = isSelectedDisplayFormat(state.filters.format)
    ? styles.gridOverride4Columns
    : styles.gridOverride3Columns;

  return (
    <>
      {isFetching && <Spinner variant='fullScreenOverlay' />}
      <section>
        <Filters
          state={state}
          dispatch={dispatch}
          availableFormatFilters={availableAdProducts}
          filtersUsed={[FILTERS.FORMAT, FILTERS.START_DATE, FILTERS.END_DATE]}
        />
      </section>
      <section className={styles.keyMetricsSection} data-testid='key-metrics-section'>
        <Highlights data={keyMetricsData} className={highlightsClassName} />
      </section>
      <section data-testid='chart-type-buttons-section'>
        <div className={styles.chartButtonsRowContainer}>
          <ChartTypeButtons
            selectedFormat={state.filters.format}
            selectedValue={selectedChartTypeButton}
            setSelectedValue={setSelectedChartTypeButton}
          />
          <ExportReport />
        </div>
        <PerformanceCharts selectedChartType={selectedChartTypeButton} data={data} />
      </section>
    </>
  );
}

export default PerformanceTabPrevVersion;

import { useContext } from 'react';

import { FeatureFlags, FeatureFlagsContext } from '@shared/providers/FeatureFlagsProvider/FeatureFlagsProvider.tsx';

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }

  return context;
};

import { apiRoutes } from '@features/campaigns/performance/consts/apiRoutes';
import {
  CampaignPerformanceAllType,
  CampaignPerformanceDisplayType,
  CampaignPerformanceVideoType,
} from '@features/campaigns/performance/types/performance.ts';
import { apiClient } from '@shared/api/ApiClient.ts';
import { CampaignId } from '@shared/types/campaign.ts';

export interface CampaignPerformanceQueryParams {
  startDate?: string;
  endDate?: string;
}

export async function getCampaignPerformanceDisplayType(
  campaignId: CampaignId,
  query: CampaignPerformanceQueryParams,
): Promise<CampaignPerformanceDisplayType | null> {
  return await apiClient.get<CampaignPerformanceDisplayType>(apiRoutes.getCampaignPerformanceDisplayType(campaignId), {
    ...query,
  });
}

export async function getCampaignPerformanceVideoType(
  campaignId: CampaignId,
  query: CampaignPerformanceQueryParams,
): Promise<CampaignPerformanceVideoType | null> {
  return await apiClient.get<CampaignPerformanceVideoType>(apiRoutes.getCampaignPerformanceVideoType(campaignId), {
    ...query,
  });
}

export async function getCampaignPerformanceAllType(
  campaignId: CampaignId,
  query: CampaignPerformanceQueryParams,
): Promise<CampaignPerformanceAllType | null> {
  return await apiClient.get<CampaignPerformanceAllType>(apiRoutes.getCampaignPerformanceAllType(campaignId), {
    ...query,
  });
}

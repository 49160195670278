import { Nullable } from 'primereact/ts-helpers';
import { Reducer as ReducerType } from 'react';

import { CampaignFormat } from '@features/campaigns/shared/types/campaign';
import { ValueOf } from '@shared/types/common';

export const Actions = {
  CHANGE_START_DATE_FILTER: 'change-start-date-filter',
  CHANGE_END_DATE_FILTER: 'change-end-date-filter',
  CHANGE_FORMAT_FILTER: 'change-format-filter',
  CLEAR_FILTERS: 'clear-filters',
} as const;

type Action = ValueOf<typeof Actions>;

export interface CampaignDetailsReducerState {
  filters: {
    format?: CampaignFormat[];
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
  };
}

export interface CampaignDetailsReducerAction {
  type: Action;
  payload?: Partial<CampaignDetailsReducerState>;
}

export type Reducer = ReducerType<CampaignDetailsReducerState, CampaignDetailsReducerAction>;

import { BarElement, Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { BarColor, ChartKey } from '@features/campaigns/performance/shared/components/HorizontalBarChart/consts.ts';
import { HorizontalBarChartData } from '@features/campaigns/performance/shared/components/HorizontalBarChart/HorizontalBarChart.types.ts';
import {
  generateChartData,
  generateChartOptions,
} from '@features/campaigns/performance/shared/components/HorizontalBarChart/utils.ts';

import styles from './HorizontalBarChart.module.scss';

ChartJS.register(ChartDataLabels, BarElement);

interface HorizontalBarChartProps {
  data: HorizontalBarChartData;
  barColor: BarColor;
  dataTestId: string;
  chartKey: ChartKey;
}

function HorizontalBarChart({ data, barColor, dataTestId, chartKey }: HorizontalBarChartProps) {
  const barAndGapHeight = 45;
  const legendHeight = 60;
  const maxHeightOfChartWrapper = 21 * barAndGapHeight;
  const isScrollableChart = data.labels.length * barAndGapHeight > maxHeightOfChartWrapper;
  const scrollbarWidth = 6;

  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const [shouldDisplayChartShadow, setShouldDisplayChartShadow] = useState(isScrollableChart);
  const [chartWidth, setChartWidth] = useState(chartWrapperRef.current?.clientWidth);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const target = event.target as HTMLDivElement;

    const isScrollAtBottom = target.scrollHeight - target.scrollTop === target.clientHeight;

    setShouldDisplayChartShadow(!isScrollAtBottom);
  };

  useEffect(() => {
    setShouldDisplayChartShadow(isScrollableChart);

    if (chartWrapperRef.current?.clientWidth) {
      setChartWidth(
        isScrollableChart ? chartWrapperRef.current.clientWidth + scrollbarWidth : chartWrapperRef.current.clientWidth,
      );
    }
  }, [chartKey, chartWrapperRef]);

  return (
    <div
      ref={chartWrapperRef}
      onScroll={(event) => (isScrollableChart ? handleScroll(event) : null)}
      // TODO: temporarily remove height property when height is determined dynamically. Issue with scrollbar
      // style={{
      //   height: isScrollableChart ? maxHeightOfChartWrapper : data.labels.length * barAndGapHeight + legendHeight,
      // }}
      className={cn(styles.chartWrapper, { [styles.shadow]: shouldDisplayChartShadow })}
    >
      {chartWidth && (
        <Bar
          data={generateChartData(data, barColor)}
          options={generateChartOptions(data, barColor)}
          height={data.labels.length * barAndGapHeight + legendHeight}
          width={chartWidth}
          data-testid={`${dataTestId}-chart`}
          key={chartKey}
        />
      )}
    </div>
  );
}

export default HorizontalBarChart;

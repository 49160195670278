import cn from 'classnames';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { MouseEvent, MutableRefObject } from 'react';

import styles from './PptxMenuItem.module.scss';

// TODO - remove whole file when working on https://clearcodehq.atlassian.net/browse/PBLTL-1184

interface PptxMenuItemProps {
  item: MenuItem;
  handleOnClick: () => void;
  menuReference: MutableRefObject<null | Menu>;
}

export default function PptxMenuItem({ item, handleOnClick, menuReference }: PptxMenuItemProps) {
  const hideMenu = (event: MouseEvent) => {
    menuReference.current?.toggle(event);
  };

  const handleOnItemClick = (event: MouseEvent) => {
    handleOnClick();
    hideMenu(event);
  };

  return (
    <a
      href='#'
      className={cn('p-menuitem-link', styles.pptxMenuItem)}
      onClick={(event) => handleOnItemClick(event)}
      role='menuitem'
      tabIndex={0}
      data-testid={`${item.label}-pptx-menu-item`}
    >
      <span className={cn('p-menuitem-icon pi pi-file-o', styles.icon)} />
      <span className={cn('p-menuitem-text', styles.text)}>{item.label}</span>
    </a>
  );
}

import { ValueOf } from '@shared/types/common.ts';

// TODO - remove whole file when working on https://clearcodehq.atlassian.net/browse/PBLTL-1184

export const PptxTypes = {
  REPORT: 'report',
  DETAILS: 'details',
} as const;

export type PptxType = ValueOf<typeof PptxTypes>;

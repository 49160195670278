import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import cn from 'classnames';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { FormProvider, SubmitHandler, useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess';
import { displayApiErrorsOnFormFields } from '@features/campaigns/builder/utils/displayApiErrorsOnFormFields.ts';
import { FieldAgency } from '@features/campaigns/details/components/FormFields/BillingForm/FieldAgency/FieldAgency.tsx';
import { FieldAuthorizedBudget } from '@features/campaigns/details/components/FormFields/BillingForm/FieldAuthorizedBudget/FieldAuthorizedBudget';
import { FieldBillingContact } from '@features/campaigns/details/components/FormFields/BillingForm/FieldBillingContact/FieldBillingContact.tsx';
import { FieldPurchaseOrder } from '@features/campaigns/details/components/FormFields/BillingForm/FieldPurchaseOrder/FieldPurchaseOrder.tsx';
import { BillingFormFieldTypes } from '@features/campaigns/details/components/FormFields/FieldTypes.ts';
import AuthorizedBudgetReadOnly from '@features/campaigns/details/containers/BillingDetails/AuthorizedBudgetReadOnly/AuthorizedBudgetReadOnly';
import {
  AuthorizedBudgetPayload,
  BillingDetailsData,
  BillingDetailsPayload,
  restoreBillingDetailsFormValues,
} from '@features/campaigns/details/containers/BillingDetails/BillingDetails.dto.ts';
import {
  billingDetailsValidationSchema,
  BillingDetailsValues,
} from '@features/campaigns/details/containers/BillingDetails/BillingDetails.types.ts';
import { updateAuthorizedBudget } from '@features/campaigns/details/services/authorizeBudget';
import { updateBillingDetails } from '@features/campaigns/details/services/billingDetails.ts';
import { Spinner } from '@features/campaigns/shared/components/Spinner/Spinner.tsx';
import { QUERY_KEYS } from '@features/campaigns/shared/consts/queryKeys.ts';
import { AccessRight, AccessRights } from '@shared/api/commonApiInterfaces.ts';
import { Item } from '@shared/components/Review/Item/Item';
import { SuccessMessage } from '@shared/components/Toast/SuccessMessage/SuccessMessage.tsx';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { CampaignId } from '@shared/types/campaign.ts';
import { isApiError } from '@shared/types/typeGuards';
import { ErrorStatus } from '@shared/utils/errorStatus';
import { formatNameWithCode } from '@shared/utils/formatNameWithCode.ts';

import styles from './BillingDetails.module.scss';

interface BillingDetailsProps {
  data: BillingDetailsData;
  accessRights: AccessRight[];
}

const handleCreditCheckError = (setError: UseFormSetError<BillingDetailsPayload>, error: unknown) => {
  if (isAxiosError(error) && isApiError(error)) {
    const apiErrorStatus = error.response?.data?.status;

    if (apiErrorStatus === ErrorStatus.CREDIT_CHECK_FAILED) {
      setError(BillingFormFieldTypes.BILLING_CONTACT, {
        type: 'manual',
        message: t('api.error.custom.creditCheck', {
          ns: i18nNameSpace.API,
        }),
      });
    }
  }
};

const editBillingDetailsAccessRights: AccessRight[] = [
  AccessRights.EDIT_AGENCY,
  AccessRights.EDIT_AUTHORIZED_BUDGET,
  AccessRights.EDIT_BILLING_CONTACT,
  AccessRights.EDIT_PURCHASE_ORDER,
];

const checkIfHasAnyEditBillingDetailsAccessRight = (accessRights: AccessRight[]) => {
  return editBillingDetailsAccessRights.some((editBillingDetailsAccessRight) =>
    accessRights.includes(editBillingDetailsAccessRight),
  );
};

export default function BillingDetails({ data, accessRights }: BillingDetailsProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const { campaignId } = useParams();
  const queryClient = useQueryClient();
  const { agency, billingContact, purchaseOrder } = data;
  const formValues = restoreBillingDetailsFormValues(data);
  const hasAnyEditBillingDetailsAccessRight = checkIfHasAnyEditBillingDetailsAccessRight(accessRights);
  const authorizedBudgetPrevValue = useRef(formValues.authorizedBudget);

  const form = useForm<BillingDetailsValues>({
    defaultValues: formValues,
    resolver: zodResolver(billingDetailsValidationSchema),
  });

  const authorizedBudgetFieldCurrentValue = form.watch(BillingFormFieldTypes.AUTHORIZED_BUDGET);

  const agencyNameWithCode = formatNameWithCode(agency?.name, agency?.code);
  const billingContactWithCode = formatNameWithCode(billingContact?.name, billingContact?.code);

  const resetFormDirtyState = () => {
    const values = form.getValues();
    form.reset(values);
  };

  const { mutate: updateBillingDetailsMutation, isPending: updateBillingDetailsIsPending } = useMutation({
    mutationFn: ({ payload, campaignId }: { payload: BillingDetailsPayload; campaignId: CampaignId }) =>
      updateBillingDetails(payload, campaignId),
    onError: (error) => {
      handleCreditCheckError(form.setError, error);
      displayApiErrorsOnFormFields(form.setError, error);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CAMPAIGN_DETAILS] });
      toast.success(
        <SuccessMessage message={t('api.success.campaign.billingDetailsSaved', { ns: i18nNameSpace.API })} />,
      );

      const shouldResetAuthorizedBudget =
        authorizedBudgetPrevValue.current === authorizedBudgetFieldCurrentValue && authorizedBudgetFieldCurrentValue;

      if (shouldResetAuthorizedBudget) {
        form.setValue(BillingFormFieldTypes.AUTHORIZED_BUDGET, undefined);
        authorizedBudgetPrevValue.current = data?.authorizedBudget;
      }

      resetFormDirtyState();
    },
  });

  const { mutate: updateAuthorizedBudgetMutation, isPending: updateAuthorizedBudgetIsPending } = useMutation({
    mutationFn: ({ payload, campaignId }: { payload: AuthorizedBudgetPayload; campaignId: CampaignId }) =>
      updateAuthorizedBudget(payload, campaignId),
    onError: (error) => {
      displayApiErrorsOnFormFields(form.setError, error);
    },
    onSuccess: async (data) => {
      toast.success(
        <SuccessMessage message={t('api.success.campaign.authorizedBudgetUpdated', { ns: i18nNameSpace.API })} />,
      );
      resetFormDirtyState();
      authorizedBudgetPrevValue.current = data?.authorizedBudget;
    },
  });

  const clearAgencyName = () => {
    form.setValue(BillingFormFieldTypes.AGENCY, undefined);
  };

  const clearBillingContactName = () => {
    form.setValue(BillingFormFieldTypes.BILLING_CONTACT, '');
  };

  const submitForm: SubmitHandler<BillingDetailsValues> = ({ authorizedBudget, ...billingDetailsPayload }) => {
    if (!campaignId) {
      return null;
    }

    const fieldsToUpdate = form.formState.dirtyFields;

    if (fieldsToUpdate.agencyId || fieldsToUpdate.billingContactId || fieldsToUpdate.purchaseOrder) {
      updateBillingDetailsMutation({ payload: billingDetailsPayload, campaignId });
    }

    if (fieldsToUpdate.authorizedBudget && authorizedBudget) {
      updateAuthorizedBudgetMutation({ payload: { authorizedBudget }, campaignId });
    }
  };

  const submitDisabled = !form.formState.isDirty || updateBillingDetailsIsPending || updateAuthorizedBudgetIsPending;

  return (
    <FormProvider {...form}>
      <form className={styles.wrapper} onSubmit={form.handleSubmit(submitForm)} autoComplete={'off'} role='form'>
        {(updateBillingDetailsIsPending || updateAuthorizedBudgetIsPending) && <Spinner variant='elementOverlay' />}

        <div className='col-12'>
          <hr className={styles.divider} />
        </div>
        <div className='col-12'>
          <AllowAccess
            fallbackView={
              accessRights.includes(AccessRights.READ_AGENCY) && (
                <Item label={t('page.billingForm.agencyField.label')} value={agencyNameWithCode || '-'} />
              )
            }
            additionalCondition={accessRights.includes(AccessRights.EDIT_AGENCY)}
          >
            <FieldAgency clearSelectedValuesHandler={clearAgencyName} restoredInputValue={agencyNameWithCode} />
          </AllowAccess>
        </div>
        <div className='col-12'>
          <AllowAccess
            fallbackView={
              accessRights.includes(AccessRights.READ_BILLING_CONTACT) && (
                <Item label={t('page.billingForm.billingContactField.label')} value={billingContactWithCode || '-'} />
              )
            }
            additionalCondition={accessRights.includes(AccessRights.EDIT_BILLING_CONTACT)}
          >
            <FieldBillingContact
              clearSelectedValuesHandler={clearBillingContactName}
              restoredInputValue={billingContactWithCode}
              advertiserId={data.advertiserId}
              validFromDate={data.validFromDate}
              validToDate={data.validToDate}
            />
          </AllowAccess>
        </div>
        <div className='col-12'>
          <AllowAccess
            fallbackView={
              accessRights.includes(AccessRights.READ_PURCHASE_ORDER) && (
                <Item label={t('page.billingForm.orderField.label')} value={purchaseOrder || '-'} />
              )
            }
            additionalCondition={accessRights.includes(AccessRights.EDIT_PURCHASE_ORDER)}
          >
            <FieldPurchaseOrder />
          </AllowAccess>
        </div>
        <AllowAccess
          fallbackView={
            accessRights.includes(AccessRights.READ_AUTHORIZED_BUDGET) && (
              <AuthorizedBudgetReadOnly authorizedBudget={formValues.authorizedBudget} />
            )
          }
          additionalCondition={accessRights.includes(AccessRights.EDIT_AUTHORIZED_BUDGET)}
        >
          <div className='col-12'>
            <hr className={cn(styles.divider, styles.noMargins)} />
          </div>
          <div className='col-12'>
            <FieldAuthorizedBudget />
          </div>
        </AllowAccess>

        {hasAnyEditBillingDetailsAccessRight && (
          <div className={styles.footer}>
            <Button data-testid='submit-billing-form' type='submit' outlined disabled={submitDisabled}>
              {t('page.billingForm.common.save')}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

import { CampaignFormat } from '@features/campaigns/shared/types/campaign.ts';

export const isSelectedDisplayFormat = (selectedFormat: CampaignFormat[] | undefined) => {
  return selectedFormat?.length === 1 && selectedFormat.includes('DISPLAY');
};

export const isSelectedVideoFormat = (selectedFormat: CampaignFormat[] | undefined) => {
  return selectedFormat?.length === 1 && selectedFormat.includes('VIDEO');
};

export const isSelectedAllFormat = (selectedFormat: CampaignFormat[] | undefined) => {
  return selectedFormat && (selectedFormat.length === 0 || selectedFormat.length > 1);
};

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { GraphDataType } from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/Charts.types.ts';
import {
  generateChartData,
  htmlLegendPlugin,
  options,
  PerformanceChartsValue,
  PerformanceChartsValueType,
} from '@features/campaigns/performance/components/PerformanceTabPrevVersion/components/Charts/utils/generateChartData.ts';

import styles from './Charts.module.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface PerformanceChartsProps {
  data: GraphDataType;
  selectedChartType: PerformanceChartsValueType;
}

function PerformanceCharts({ data, selectedChartType }: PerformanceChartsProps) {
  const graphData = generateChartData(data, selectedChartType);
  const showY1Axis =
    selectedChartType === PerformanceChartsValue.VISITS || selectedChartType === PerformanceChartsValue.ACTIONS;

  if (graphData) {
    return (
      <div className={styles.wrapper}>
        <Line className={styles.chart} options={options(showY1Axis)} data={graphData} plugins={[htmlLegendPlugin]} />
        <div id='label-container'></div>
        <div id='chartjs-tooltip'></div>
      </div>
    );
  }

  return <></>;
}

export default PerformanceCharts;
